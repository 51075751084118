html,
body,
#__next {
    height: 100%;
    overflow: hidden;
}

.theme-green {
    --bet-btn-bg: #21b51d;
    --bet-btn-bg-hover: #23ca1e;
    --bet-btn-bg-active: #08a303;
    --bet-btn-shadow: #056d1d;
    --btn-bg-color: #21b51d;
    --btn-hover: #056d1d;
}

.theme-purple {
    --bet-btn-bg: linear-gradient(67.83deg, #2898ff -3.76%, #db33f7 81.26%);
    --bet-btn-bg-hover: linear-gradient(
        67.83deg,
        #f7339d -3.76%,
        #2898ff 89.2%
    );
    --bet-btn-bg-active: linear-gradient(
        67.83deg,
        #f7339d -3.76%,
        #2898ff 89.2%
    );
    --bet-btn-shadow: rgba(112, 65, 211, 0.7);
}

.theme-red {
    --bet-btn-bg: #fa114f;
    --bet-btn-bg-hover: #fa114f;
    --bet-btn-bg-active: #ee1d55;
    --bet-btn-shadow: #890026;
    --btn-bg-color: #fa114f;
    --btn-hover: #fa114f;
    --btn-hover-color: #790021;
}

html {
    min-width: 320px;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    background: transparent;
    overflow: hidden;
}

.os-scrollbar-vertical {
    .os-scrollbar-handle {
        right: -14px !important;
    }

    .os-scrollbar-track {
        padding-right: 2px !important;
    }
}

.os-scrollbar {
    &.os-theme-light {
        --os-size: 2px;
        --os-padding-perpendicular: 25px;
        --os-track-border-radius: 10px;
        --os-track-bg: #777;
        --os-track-bg-hover: #777;
        --os-track-bg-active: #777;
        --os-handle-border-radius: 80px;
        --os-handle-bg: #d9d9d9;
        --os-handle-bg-hover: #d9d9d9;
        --os-handle-bg-active: #d9d9d9;
        --os-handle-border: 3px solid #d9d9d9;
        --os-handle-min-size: 30px;
        --os-handle-max-size: 30px;
        --os-handle-perpendicular-size: 30px;
        --os-handle-perpendicular-size-hover: 30px;
        --os-handle-perpendicular-size-active: 30px;
        --os-handle-interactive-area-offset: 2px;
    }
}
