@tailwind base;

@layer base {
    html {
        -webkit-tap-highlight-color: transparent;
    }

    img {
        pointer-events: none;
        user-drag: none;
    }
}
