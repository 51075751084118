@tailwind utilities;

@layer utilities {
    .filter-none {
        filter: none;
    }

    .filter-hover {
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                filter: saturate(1.8) brightness(1.05);
            }
        }
        transition: all 0.2s ease-in-out;
    }

    .filter-grayscale {
        filter: grayscale(100%);
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}
